import assert from "assert"
import { Stack, TextField, Checkbox, InputLabel, styled } from "@mui/material"
import { LoadingButton } from "@mui/lab"
import { DatePicker } from "@mui/x-date-pickers"
import { zonedTimeToUtc } from "date-fns-tz"
import { CollapsibleCard } from "components"
import { useNotification } from "context/notification"
import { Controller, useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { formatDate, parseDate } from "utils/date"
import usePostPayScheduleDate from "../../query-hooks/usePostPayScheduleDate"
import { ErrorDisplay } from "./components/ErrorDisplay/ErrorDisplay"

function formatDateForSubmission(date: Date) {
  return formatDate(zonedTimeToUtc(date, "UTC"), "yyyy-MM-dd")
}

export const AddDate = () => {
  const notify = useNotification()
  const { id: payScheduleId } = useParams()
  assert(payScheduleId)

  const { mutate, isLoading, error } = usePostPayScheduleDate(payScheduleId, {
    onSuccess: () => {
      reset()
      notify.that("pay schedule date").successfully("added")
    },
  })

  const { handleSubmit, reset, control } = useForm<{
    pay_period_start: Date | null
    paid_on: Date | null
    blackout_period_start: Date | null
    is_estimate: boolean | null
  }>({
    defaultValues: {
      pay_period_start: null,
      paid_on: null,
      blackout_period_start: null,
      is_estimate: false,
    },
  })

  const handleFormSubmit = handleSubmit(
    ({ pay_period_start, paid_on, blackout_period_start, is_estimate }) => {
      assert(pay_period_start)
      assert(paid_on)

      const formattedPayPeriodStart = formatDateForSubmission(pay_period_start)
      const formattedPaidOn = formatDateForSubmission(paid_on)
      const formattedBlackoutPeriodStart = blackout_period_start
        ? formatDateForSubmission(blackout_period_start)
        : undefined

      assert(formattedPayPeriodStart)
      assert(formattedPaidOn)
      if (blackout_period_start) {
        assert(formattedBlackoutPeriodStart)
      }

      mutate({
        pay_period_start: formattedPayPeriodStart,
        paid_on: formattedPaidOn,
        blackout_period_start: formattedBlackoutPeriodStart ?? undefined,
        is_estimate: is_estimate ?? false,
      })
    }
  )

  return (
    <CollapsibleCard title="Add Date" type="action">
      <form id="add-pay-schedule-date" onSubmit={handleFormSubmit}>
        <Stack spacing={4}>
          <ErrorDisplay error={error} />
          <Controller
            control={control}
            name="pay_period_start"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Pay Period Start"
                {...field}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              required: true,
              validate: (value) =>
                !!parseDate(value) || "Date is not correctly formatted",
            }}
          />
          <Controller
            control={control}
            name="paid_on"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Paid On"
                {...field}
                renderInput={(params) => (
                  <TextField
                    required
                    fullWidth
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              required: true,
              validate: (value) =>
                !!parseDate(value) || "Date is not correctly formatted",
            }}
          />
          <Controller
            control={control}
            name="blackout_period_start"
            render={({ field, fieldState: { error } }) => (
              <DatePicker
                label="Blackout Period Start"
                {...field}
                renderInput={(params) => (
                  <TextField
                    fullWidth
                    error={Boolean(error)}
                    helperText={error && error.message}
                    {...params}
                  />
                )}
              />
            )}
            rules={{
              validate: (value) =>
                value
                  ? !!parseDate(value) || "Date is not correctly formatted"
                  : true,
            }}
          />
          <Controller
            control={control}
            name="is_estimate"
            render={({ field }) => (
              <CheckboxContainer>
                <InputLabel>Is Estimate</InputLabel>
                <Checkbox {...field} />
              </CheckboxContainer>
            )}
          />
          <LoadingButton type="submit" variant="contained" loading={isLoading}>
            Add Date
          </LoadingButton>
        </Stack>
      </form>
    </CollapsibleCard>
  )
}

const CheckboxContainer = styled("div")`
  display: flex;
  align-items: center;
  justify-content: left;
`
