import type { ControllerProps, FieldValues } from "react-hook-form"
import { Controller } from "react-hook-form"
import { Select, type TextFieldProps } from "@mui/material"

type LiftedProps =
  | "label"
  | "sx"
  | "children"
  | "helperText"
  | "disabled"
  | "hidden"
  | "onChange"

export type ControlledSelectProps<T extends FieldValues> = Omit<
  ControllerProps<T>,
  "render"
> &
  Pick<TextFieldProps, LiftedProps> & {
    fieldProps?: Omit<TextFieldProps, LiftedProps | keyof ControllerProps<T>>
  } & { native?: boolean }

export function ControlledSelect<T extends FieldValues>({
  name,
  control,
  children,
  rules = { required: true },
  native = true,
  ...controllerProps
}: ControlledSelectProps<T>) {
  const size = controllerProps.fieldProps?.size
  return (
    <Controller
      {...controllerProps}
      name={name}
      control={control}
      rules={rules}
      render={({ field, fieldState }) => (
        <Select
          defaultValue={controllerProps.defaultValue}
          disabled={controllerProps.disabled}
          native={native}
          error={Boolean(fieldState.error)}
          size={size}
          {...field}
        >
          {children}
        </Select>
      )}
    />
  )
}
