import { useQuery } from "@tanstack/react-query"
import { endpoints } from "api"

export const getQueryKey = () => ["due-diligence-reviewed-checks"] as const

function useDueDiligenceReviewedChecks(
  type = "WATCHLIST",
  provider = "DOW_JONES"
) {
  const today = new Date()
  today.setHours(0, 0, 0, 0)
  const endpoint = endpoints.dueDiligence.reviewedManualChecks(type, provider)

  return useQuery([...getQueryKey(), type, provider], endpoint.get, {
    select: (data) => data.data.data,
  })
}

useDueDiligenceReviewedChecks.getQueryKey = getQueryKey

export default useDueDiligenceReviewedChecks
