import { Page } from "components/Page/Page"
import { TabNav } from "components"

import { Outlet, useLocation } from "react-router-dom"
import { routes } from "routes"

export default function DueDiligenceReview() {
  const { pathname } = useLocation()

  const TABS = [
    { label: "Unreviewed", href: routes.dueDiligenceUnreviewedQueue() },
    { label: "Reviewed", href: routes.dueDiligenceReviewedList() },
  ]

  return (
    <Page
      headerTitle="Due Diligence Reviews"
      tabs={<TabNav tabs={TABS} currentTab={pathname} />}
      scrollToTopButton
    >
      <Outlet />
    </Page>
  )
}
