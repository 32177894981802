import { Tooltip } from "@mui/material"
import type { GridColumns } from "@mui/x-data-grid"
import type { DueDiligenceCheck } from "api"
import { dateTimeColumnDefaultOptions } from "utils/columns"
import JsonPopout from "components/JsonPopout/JsonPopout"

const COLUMNS: GridColumns<DueDiligenceCheck> = [
  {
    field: "full_name",
    headerName: "Full Name",
    width: 140,
  },
  {
    field: "dob",
    headerName: "DOB",
    width: 140,
  },
  {
    field: "product",
    headerName: "Product",
    width: 140,
  },
  {
    field: "reviewer_username",
    headerName: "Reviewer",
    width: 140,
  },
  {
    field: "created_at",
    headerName: "Reviewed at",
    width: 160,
    ...dateTimeColumnDefaultOptions(),
    valueParser: undefined,
  },
  {
    field: "properties",
    headerName: "Properties",
    renderCell: ({ value }) => (
      <JsonPopout text="Show" title="Properties" json={value} />
    ),
  },
  {
    field: "approval",
    headerName: "Approval",
    width: 140,
    renderCell: ({ value }) => (
      <Tooltip title={value.properties.reason ?? "no reason provided"} arrow>
        <>{value.result === "clear" ? "True" : "False"}</>
      </Tooltip>
    ),
  },
  {
    field: "reason",
    headerName: "Reason",
    width: 200,
  },
  {
    field: "execution_result",
    headerName: "Result",
    renderCell: ({ value }) =>
      value?.status_code ? (
        <JsonPopout
          btnVariant="outlined"
          btnColor={
            value?.status_code >= 200 && value?.status_code < 300
              ? "success"
              : "error"
          }
          text={
            value?.status_code >= 200 && value?.status_code < 300
              ? "success"
              : "error"
          }
          title="Execution Result"
          json={value}
        />
      ) : value?.created_at ? (
        <JsonPopout
          btnVariant="outlined"
          btnColor="secondary"
          text={"running"}
          title="Execution Result"
          json={value}
        />
      ) : (
        "-"
      ),
  },
]

export default COLUMNS
