import useDueDiligenceReviewedChecks from "query-hooks/useDueDiligenceReviewedChecks"
import COLUMNS from "./DueDiligenceReviewedList.columns"
import DueDiligenceGrid from "./DueDiligenceGrid"

export default function DueDiligenceReviewedList() {
  const { data, isLoading, error } = useDueDiligenceReviewedChecks(
    "WATCHLIST",
    "DOW_JONES"
  )
  const columns = data
    ?.map((r) => ({
      approval: r,
      ...r,
      product: "Credit",
      dob: r?.dob,
      reason: r?.properties?.reason,
      reviewer_username: r?.properties?.reviewer_username,
      full_name: `${r?.first_name ?? ""} ${r?.last_name ?? ""}`,
    }))
    .filter((row) => !Boolean(row.provider === "DOW_JONES"))

  return (
    <DueDiligenceGrid
      columnDefinition={COLUMNS}
      columns={columns}
      error={error}
      isLoading={isLoading}
    />
  )
}
