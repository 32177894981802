import { Grid, Skeleton } from "@mui/material"
import type { DueDiligenceCheck } from "api"

import { CollapsibleCard, SimpleGrid } from "components"

import type { GridColumns } from "@mui/x-data-grid"

interface DueDiligenceReviewedListProps {
  columnDefinition: GridColumns<DueDiligenceCheck>
  columns: DueDiligenceCheck[] | undefined
  error: unknown
  isLoading: boolean
}

export default function DueDiligenceReviewedList({
  columnDefinition,
  columns,
  error,
  isLoading,
}: DueDiligenceReviewedListProps) {
  return (
    <Grid item xs={16}>
      <CollapsibleCard type="main">
        {isLoading ? (
          <Skeleton
            role="progressbar"
            height={600}
            sx={{ transform: "none" }}
          />
        ) : (
          <>
            <SimpleGrid
              idField="due_diligence_check_id"
              initialState={{
                sorting: {
                  sortModel: [{ field: "created_at", sort: "desc" }],
                },
              }}
              rows={columns || []}
              columns={columnDefinition}
              error={error}
              quickFilter
              pagination
              disableColumnFilter={false}
              rowsPerPageOptions={[100, 500, 99999]}
              isLoading={isLoading}
            />
          </>
        )}
      </CollapsibleCard>
    </Grid>
  )
}
